export function isCurrentPathExact(path: string, currentPath: string) {
    return currentPath === path || currentPath === `${path}/`;
}

export function startsWithPath(path: string, currentPath: string) {
    const routeSegments = path.split('/').filter(Boolean);
    const currentPathSegments = currentPath.split('/').filter(Boolean);
    return routeSegments.every((segment, index) =>
        currentPathSegments[index] === segment
    );
}